import React from "react";
import Navbar from "../components/navbar";
import Seo from "../components/seo";
import ServicesCard from "../components/ServicesCard";
import Heading from "../components/heading/heading";
import Footer from "../components/footer/Footer";

function Services() {
  return (
    <>
      <Seo
        title="Our Products & Services"
        description="We are a full service digital agency that specializes in web design, development, and digital marketing. We are a team of creative and experienced individuals who are passionate about creating beautiful and functional websites."
      />
      <Navbar />
      <div className="flex flex-col justify-center">
        <Heading>{`Our Products & Services`}</Heading>
        <div className="w-full flex flex-wrap p-4 justify-center items-center">
          <ServicesCard
            title="Penetration Testing"
            content="A penetration test emulates the role of an attacker attempting to gain access to an internal
            network with or without internal resources or inside knowledge. A DuskSec Security Pentester
            attempts to gather sensitive information through open-source intelligence (OSINT),
            including employee information, historical breached passwords, and more that can be
            leveraged against external systems to gain internal network access. The engineer also
            performs scanning and enumeration to identify potential vulnerabilities in hopes of
            exploitation. All testing performed is based on the NIST SP 800-115 Technical Guide to
            Information Security Testing and Assessment, OWASP Testing Guide (v4), and customized
            testing frameworks. A DuskSec signed report of the PenTest is provided to explain all
            exploitable loopholes found which includes a security rating and tips to improve on it if
            necessary."
            price="$499 or 449 USDT"
            summary="Penetration testing emulates attacks from both inside and from outside your network and
            web applications."
          />
          <ServicesCard
            title="Vulnerability Analysis"
            content="Small businesses with less online assets at stake don’t usually require a penetration test to
           successfully evaluate their security posture. Regular vulnerability scans are often an
           alternative. An engineer performs vulnerability scanning to search systems for known
           vulnerabilities without exploitation attempts with an end goal of providing a remediation
           report prioritized on risk."
            price="$399 or 349 USDT"
            summary="Vulnerability analysis provides an in-depth assessment of the network connected
            peripherals and list of attack vectors to evaluate and prioritize exposures in your
            organization."
          />
          <ServicesCard
            title="Physical Assets Security"
            content="DuskSec certified physical systems for surveillance including CCTV’s , Biometric Tailgating
            protection systems , Electronic Gate Locks , Infrared motion detectors , Intrusion detection
            alarm system . Consultations for requirements and installation is included in price of
            product."
            summary="DuskSec certified physical systems for servileness including CCTV’s , Biometric Tailgating
            protection systems , etc."
            price="$249 or 199 USDT"
          />
          <ServicesCard
            title="Block-chain Based Applications"
            content="There are a lot of security mechanism in place such as firewalls and encryption standards
            however all of them have one or the other limitation or flaw such as complexity , human
            error , availability of required resources and computation , etc. So to completely secure
            any dataset in a network ecosystem a block-chain based decentralised system proposes
            most merits.
            DuskSec’s team of engineers provide consultation and suggest plan of action to
            implement technologies based on the requirements provided by customer. The secure
            system is designed either on Hyperledger or Solidity based Smart contracts to ensure no
            system mutability can be attained in the future."
            summary="Blockchain based immutable ledger application development , Deployment , Web Api
            integration and support."
            price="$2599 or 2549 USDT"
          />
          <ServicesCard
            title="Access Rights Management"
            content="Access Rights manager helps provide you IT Security team to understand and act on high
            risk access to minimize the impact of insider threats that’s helps to improve compliances
            by detecting changes. This system enables fast , accurate account provisioning and helps
            configure delegate access rights management."
            summary="An Access Rights manager helps you to Manage and Audit access across your whole IT
            infrastructure."
            price="$2099 or 2049 USDT"
          />
          <ServicesCard
            title="Security Event Manager"
            content="The security Event manager provides IT Admin with centralised log collection and
            normalisation with automated threat detection and response. The system is integrated with
            compliance reporting tools and intuitive user friendly dashboard. It also provides a built-in
            file integrity monitoring system as well."
            summary="Improve your security posture and quickly demonstrate compliance with a lightweight,
            ready-to-use, and affordable security information and event management solution."
            price="$2999 or 2959 USDT"
          />
          <ServicesCard
            title="Social Engineering"
            content="Humans are often the weakest link in an organization. Our engineers are capable of
            performing detailed phishing, vishing, whaling, and other advanced social engineering
            attacks with an end goal of evaluating your company’s social engineering posture as well
            as gaining access to requested sensitive data, information, PII, and more as determined
            by the customer."
            summary="Social engineering assesses the human vulnerability factor in your organization."
            price="$299 or 249 USDT"
          />
          <ServicesCard
            title="Cyber Forensics"
            content="Computer forensics is the application of investigation and analysis techniques to gather
            and preserve evidence from a particular computing device in a way that is suitable for
            presentation in a court of law. DuskSec cyber-forensics report will help you and your legal
            team in presenting a winning case in any court by providing an in-depth detail of the
            system files and log analysis. It also will help obtain details of attack vector if an intruder in
            the system was detected at any point in an IT infrastructure."
            summary="DuskSec cyber-forensics report will help you and your legal team in presenting a winning
            case in any court by providing an in-depth detail of the system files and log analysis. It also
            will help obtain details of attack vector if an intruder in the system was detected at any
            point in an IT infrastructure."
            price="$2599 or 2549 USDT"
          />
          <ServicesCard
            title="Ransomware Protection"
            content="Ransomware protection has become increasingly necessary as cybersecurity risks
            increase. This is especially because if you do suffer from a successful ransomware attack,
            it can be very difficult to remove. While there are some tools available that will aim to help
            reverse a ransomware attack, the best defence is to avoid being infected by ransomware
            in the first place. The software solutions provided by DuskSec to protect against
            ransomware are robust and prevent infection of system against all modes of Ransomware
            attacks."
            summary="If your system suffer from a successful ransomware attack, it can be very difficult to
            remove. Ransomware protection has become increasingly necessary as cybersecurity
            risks increase."
            price="$499 or 449 USDT"
          />
          <ServicesCard
            title="IT Support and Disaster Recovery"
            content="Disaster recovery is an organization’s method of regaining access and functionality to its IT
           infrastructure after events like a natural disaster, cyber attack, or even business
           disruptions related to the COVID-19 pandemic. A variety of disaster recovery (DR)
           methods is part of a disaster recovery plan depending on client requirements.
           DuskSec IT Support 24x7 is a remote service provided to clients and organisation along
           with training and documentation. Disaster Recovery consults and mitigation services are
           provided for business continuity operations. An experienced team of engineers devise a
           Disaster Recover Plan for creating, implementing and managing the disaster recovery
           plan. Along with providing remote IT support including cloud computing services.
           Price"
            summary="DuskSec Team of engineers devise a Disaster Recover Plan for creating, implementing
           and managing the disaster recovery plan. Along with providing remote IT support including
           Cloud computing services."
            price="$399 or 349 USDT"
          />
          <ServicesCard
            title="Data Loss Prevention"
            content="The DLP term refers to defending organizations against both data loss and data leakage
           prevention. Data loss refers to an event in which important data is lost to the enterprise,
           such as in a ransomware attack.
           Data loss prevention focuses on preventing illicit transfer of data outside organizational
           boundaries. Organizations typically use DLP to Protect Personally Identifiable Information
           (PII) and comply with relevant regulations Protect Intellectual Property critical for the
           organization.
           DuskSec Data Loss Prevention solutions and documentations for organizations helps to
           ensure hassle free implementation of DLP to protect and secure data and also comply with
           regulations."
            summary="DuskSec Data Loss Prevention solutions and documentations for organizations helps to
            ensure hassle free implementation of DLP to protect and secure data and also comply with
            regulations."
            price="$299 or 249 USDT"
          />
          <ServicesCard
            title="Web CyberSec"
            content="Web security must be a critical priority for every organization. Along with email, the web is
           one of the top vectors for cyberattacks. The web and the use of DNS services specifically
           are part of 91% of all malware attacks, and email and web together are a key part for 99%
           of successful breaches.
           DuskSec Web CyberSec provides cost effective solutions to protect your websites /
           domains against common website attacks such as SQL injections , Cross-Site Scripting ,
           Privilege escalation , etc."
            summary="DuskSec Web CyberSec provides cost effective solutions to protect your websites /
           domains against common website attacks such as SQL injections , Cross-Site Scripting ,
           Privilege escalation , etc."
            price="$399 or 349 USDT"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
