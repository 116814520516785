import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoShieldHalf } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "gatsby";

function ServicesCard({ title, price, content, summary }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="p-2 py-4 rounded-md bg-gray hover:bg-red group transition-all duration-500 ease-in-out xl:mx-10 w-full h-64 sm:h-64 sm:w-64 m-4">
        <div className="flex flex-col justify-between h-full">
          <div className="h-5/6">
            <div className="flex justify-center items-center select-none">
              <IoShieldHalf className="text-5xl text-red-dark group-hover:text-white" />
            </div>
            <div className="text-center text-lg font-bold text-red-dark group-hover:text-white break-words select-none">
              {title}
            </div>
            <div className="pt-4 sm:pt-2 text-base sm:text-sm text-center group-hover:text-white select-none px-2 pb-1 line-clamp-3 sm:line-clamp-4">
              {summary}
            </div>
          </div>
          <div className='h-1/6'>
            <span
              aria-hidden="true"
              onClick={openModal}
              className="px-4 py-2 flex flex-row items-center justify-center text-sm font-medium text-gray-dark group-hover:text-white hover:underline text-center rounded-md cursor-pointer select-none"
            >
              Read More <HiArrowRight className="pt-px px-px text-base" />
            </span>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 bg-black bg-opacity-70 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl text-gray-dark font-medium leading-9"
                  >
                    {title}
                  </Dialog.Title>
                  <button
                    aria-label="close button"
                    className="text-2xl text-gray-dark hover:text-red transition-all duration-300 ease-in-out"
                    onClick={closeModal}
                  >
                    <CgClose />
                  </button>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{content}</p>
                  <p className="w-100 text-center text-sm">
                    {price} |{" "}
                    <Link to="/contactus">
                      <span className="text-red-800">Get a Quote</span>
                    </Link>
                  </p>
                </div>

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex text-base justify-center px-10 py-2 font-medium transition-all duration-500 ease-in-out border-red text-red bg-transparent border border-orannge rounded-md hover:bg-red hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ServicesCard;
